<template>
  <div class="my-box" v-loading="loading">
    <el-row style="margin-bottom: 10px">
      <el-card shadow="always">
        <el-form label-width="50px" class="topS">
          <el-row :gutter="10">
            <el-col :span="2">
              <el-button @click="exportExcel" v-if="logexport != -1"
                >导出日志</el-button
              >
            </el-col>
            <el-col :span="3">
              <el-form-item label="品牌">
                <el-select
                  v-model="seekData.brandId"
                  clearable
                  filterable
                  @change="selectOne"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in brandSelectData"
                    :key="index"
                    :label="item.brandName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="门店">
                <el-select
                  v-model="seekData.hotelId"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in hotelSelectData"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="操作时间" label-width="70px">
                <el-date-picker
                  v-model="createTime"
                  type="daterange"
                  clearable
                  unlink-panels
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6" class="reset-button">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always">
        <div class="my-header-table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
            id="out-table"
          >
            <!-- <el-table-column prop="brandName" label="品牌" min-width="130"></el-table-column>
      <el-table-column prop="hotelName" label="门店" min-width="180"></el-table-column> -->
            <el-table-column
              prop="username"
              label="操作人"
              min-width="120"
            ></el-table-column>
            <el-table-column
              prop="fullName"
              label="真实姓名"
              min-width="120"
            ></el-table-column>
            <el-table-column
              prop="operation"
              label="操作描述"
              min-width="120"
            ></el-table-column>
            <el-table-column
              prop="ct"
              label="操作时间"
              min-width="150"
            ></el-table-column>
            <el-table-column prop="time" label="操作用时" min-width="120">
              <template slot-scope="scope"> {{ scope.row.time }} ms </template>
            </el-table-column>
            <!-- <el-table-column prop="method" label="执行方法" width="150"></el-table-column> -->
            <el-table-column
              prop="ip"
              label="ip地址"
              min-width="150"
            ></el-table-column>
            <el-table-column
              prop="params"
              label="请求参数"
              min-width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="resp"
              label="响应参数"
              min-width="200"
              show-overflow-tooltip
            ></el-table-column>
            <!-- <el-table-column prop="location" label="操作地点"></el-table-column> -->

            <!-- 操作按钮列 -->
            <!-- <el-table-column label="操作" width="90">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  circle
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row)"
                ></el-button>
              </template>
            </el-table-column>-->
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[5, 10, 15, 20, 100, 9999]"
            :page-size="seekData.pageSize"
            :current-page="seekData.pageNum"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>
  </div>
</template>
<script>
import {
  delLog,
  getLoglList,
  getBrandSelect,
  getHotelSelect,
  exportLog,
} from "@/api";
import FileSaver from "file-saver";
import XLSX from "xlsx";

export default {
  data() {
    return {
      height: null,
      hotelSelectData: null,
      brandSelectData: null,
      foldData: false,
      tableData: null,
      loading: false,
      total: null,
      // 查询数据
      createTime: null,
      seekData: {
        brandId: null,
        hotelId: null,
        createTimeTo: null,
        createTimeFrom: null,
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 230;
  },
  computed: {
    logexport() {
      return this.userJurisdiction.indexOf("log:export");
    },
  },
  // 注册表格组件
  components: {},
  created() {
    this.userJurisdiction = this.$store.state.roleMenu;
    // 调用初始化表格数据方法
    // this.initList(this.seekData);
    this.initialize();
  },
  methods: {
    // 初始化表格数据
    initList(obj) {
      this.loading = true;
      getLoglList(obj).then((res) => {
        if (res.status === 200) {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.seekData.pageNum > 1 && res.data.data.rows.length == 0) {
            this.seekData.pageNum = this.seekData.pageNum - 1;
            this.initList(this.seekData);
          }
        }
      });
    },
    /* 初始化下拉框 */
    // 获取品牌下拉框
    initialize(obj) {
      getBrandSelect(obj).then((res) => {
        if (res.status === 200) {
          this.brandSelectData = res.data.data;
          this.total = res.data.data.total;
        }
      });
    },
    selectOne(id) {
      this.seekData.hotelId = null;
      getHotelSelect(id).then((res) => {
        if (res.data.data) {
          this.hotelSelectData = res.data.data;
        } else {
          this.$message({
            message: "该品牌下没有门店",
            type: "warning",
          });
        }
      });
    },

    /* 展开搜索头部 */
    fold() {
      this.foldData = !this.foldData;
      if (this.foldData) {
        this.initialize();
      }
    },
    // 查询按钮
    handleSearch() {
      if (this.createTime) {
        this.seekData.createTimeTo = this.createTime[1] + " 23:59:59";
        this.seekData.createTimeFrom = this.createTime[0] + " 00:00:00";
      } else {
        this.seekData.createTimeTo = null;
        this.seekData.createTimeFrom = null;
      }
      this.seekData.pageNum = 1;
      this.initList(this.seekData);
      // if (this.seekData || this.createTime) {
      //   this.initList(this.seekData)
      // } else {
      //   this.initList(this.seekData)
      // }
    },

    exportExcel() {
      let user = JSON.parse(sessionStorage.getItem("user"));

      exportLog({
        brandId: user.brandId,
        hotelId: user.storeId,
      }).then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        downloadElement.download = "操作日志信息" + ".xlsx"; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
    // 重置按钮
    reset() {
      this.seekData.brandId = null; //品牌
      this.seekData.hotelId = null; //门店
      this.createTime = null;
      this.seekData.createTimeTo = null;
      this.seekData.createTimeFrom = null;
      this.seekData.pageNum = 1;
      this.hotelSelectData = null;
      this.initList(this.seekData);
    },
    //分页
    handleCurrentChange(cpage) {
      this.seekData.pageNum = cpage;
      this.initList(this.seekData);
    },
    handleSizeChange(psize) {
      this.seekData.pageSize = psize;
      this.seekData.pageNum = 1;
      this.initList(this.seekData);
    },
  },
};
</script>

<style scoped>
.flex-row {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
</style>
